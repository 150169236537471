
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import moment from "moment";
import { useRoute } from "vue-router";
import TimeTable from "@/costumComponents/dashboard/Timetable.vue";

export default defineComponent({
  name: "profile-overview",
  components: { TimeTable },
  setup() {
    const { t } = useI18n();

    const route = useRoute();
    const teacherID = route.params.id;

    onMounted(async () => {
      setCurrentPageBreadcrumbs(t("dashboard.timetable"), []);
    });

    return {
      t,
      moment,
      teacherID,
    };
  },
});
